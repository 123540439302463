define('routes',{
    brochureUrl: "/freebrochure/requestbrochure",
    currentLocationSearch: "/communities/currentlocation",
    currentLocationSearchHomes: "/homes/currentlocation",
    getNationalMap: "/state-adult/GetNationalMapData",
    forgotPassword: "/forgotpasswordmodal",
    getAmenityResults: "/amenity-results/results",
    getAmenityCounts: "/amenity-counts/counts",
    getStateMap: "/state-adult/GetStateMapData/state-{state}",
    GetStatesWithAdultComms: "/state-adult/GetStatesWithAdultComms",
    getAdMarkup: "/AdManager/GetAdMarkup",
    getArticleCategoryResults: "/first-time-buyers/LearningArtCategoryResults",
    getBasicHomeCard: "/getbasichomecard",
    getCounts: "/getcounts",    
    getCountsUrl: "/communities/getcountsmobile",
    getGalleryInformationBasedOnPlan: 'ajax/buildershowcase/getgalleryinformationbasedonplanid',
    getHomePageImage: "/homepage/getimagebrackgrounddata",
    getHomePageLocationBasedInformation: "/homepage/getlocationbasedinformation",
    getMapCard: 'ajax/communities/getmapcard',
    getMapPointsMobile: "/getSearchResultMapPoints",
    getMapResultsForBuilderShowcase: "ajax/buildershowcase/getMapResults",
    getResultsMobileUrl: "/communities/getresultsmobile",
    getResults: "/getresults",
    getMoreReviews: "/buildershowcase/getreviewsfromapi",
    getRatingsAndReviewsSection: "ajax/buildershowcase/getreviewssection",
    getBuilderShowCaseNearbySection: "ajax/buildershowcase/getNearbySection",
    getSingleAdMarkup: "/admanager/getsingleadmarkup",
    getSkinMarkup: "/admanager/getskinadmarkup",
    getRecommendedComms: "/freebrochure/getrecommendedcommunities/",
    getMoreNewHomes: "/basichome/getmorenewhomes ",
    logEvent: "eventlogger/logevent-",
    mobileSearch: "/mobilesearch",
    mobileTypeAhead: "/partnerlocationsindexmobile",
    mobileTypeAheadUrl: "/gettypeaheadoptions",
    mobileTypeAheadCustomUrl:"/getTypeAheadCustomHomesOptions",
    mybrochure: "/MyBrochure",
    saveToFavoritesMobile: "/saveToFavoritesMobile",
    deleteFromFavoritesMobile: "/deleteFromFavoritesMobile",
    checkSaveToFavoritesMobile: "/checkforfavorite",
    submittypeaheadsearch: "/submittypeaheadsearch",
    performanceLog: "/googleanalytics/getgoogleecommercepush",
    requestRecoCommsUrl: "/freebrochure/requestrecocomms",
    saveListingToPlanner: "/communities/savelistingtoplanner",
    search: "/search",
    sendToPhone: "/sendtophone",
    locationHandlerSearch: "/locationhandler",
    getMobileCurrentLocationHomeSection: "/getMobileCurrentLocationHomeSection",
    getMobileIpLookupHomeSection: "/homepage/getMobileIpLookupHomeSection",
    shoppingKitPanel: "/shoppingkitpanel",
    stateLanding: "/state/{state}/active-adult",
    typeAhead: "/partnerlocationsindexv2",
    typeAheadZipByPartner: "/typeahead/zipcodesbypartner",
    typeAheadAdult: "/PartnerLocationIndexForAdult",
    searchComponentMobileUrl: "/mobilesearch",
    quickMoveInChangeState: "/QuickMoveInSearch/ChangeState",
    boylSearchChangeStateUrl: "/boylsearch/ChageState",
    getMapCardsUrl: "/getListingMapCards",
    getHomeMapCardsUrl: "/getListingHomeMapCards", 
    getTestimonialsHomeRedesign: "/getTestimonialsHomeRedesign",
    sendBrochureFromEmail: "/freebrochure/requestbrochurefromemail",
    getBuildersWithReviews: "reviews/getbuildernameswithreviews",
    freeBrochureHands: "https://nhs-static-secure.akamaized.net/GlobalResources14/Default/images/no_photo/hands_290x190.jpg",
    mapIconBlue: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAeCAMAAAASJ24jAAAAgVBMVEUAAAAAkdoAkdoAkdoAkdoAkdoAkdoAkdoAkdoAkdoAkdoAkdoAkdoAkdoAkdoAkdoAkdoAkdoAkdoAkdoAkdoAkdoAkdoAkdoAkdoAkdr///8Aj9oAjNlJsOUMl9z6/f71+/7w+P3i8vvI5/fC5fa54fWW0vCOz+9buecjoN8Tl91IOPyyAAAAGXRSTlMA89OrB+3ayqRKRBwW+q+mm5N7dnFOQDEirNNLawAAANBJREFUGBl1wVWCwjAARdGXuuF6m7S47n+BhNJBPuYcPRXjII6DcaGXKqOXVfKqFLAekFaSQsBxu+GAUMoB1xy220PjgFwjcM2m9jaNg5ESLMe6c8SSyGDbXd3ZtRajGNvu686+tcRKwV3qzsVBqgnY+6n2TncLE60GYLmez1csDFbSELDOs8BQ0oIvC0lRwlsSyZvyNtXT2tAza3VCeqFecnq5XqKAThCpN6Mz05/S4JlSbxlepo8Cr9BHFEAQ6csc5vpWGlPqRxjq13KpfzwAvCEbswA2MRAAAAAASUVORK5CYII=",
    mapIconOrange: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAeCAMAAAASJ24jAAAAw1BMVEUAAAD1gh/1gSD1gh/1hxj3dyf1gh/0gh7zfx/0gh/0gR/1gh/0gR/1gR/1gRz0gR/1gh/1giD1gR/1giD1gh/0gh/1giD1giD1gyD1giD0gh/0gR72gh/0giD1gx/ygB//kifzgR/0gSD1gx/1giD0gR70giHzgh/zgiH0hB71giD////2iSv/9+/+59T3kTr2izD1hCT+9Or+7d782rz8xJX6q2b3lkP/+fP+6df93sT83L/917b6uoL5oVb4n1H2hiilpIygAAAAKnRSTlMA+/74CgbzIBDi28axmxrw6NXSzcGopqJtYVFLRD03MAO5lIiFfHtaLRl/HosJAAAA40lEQVQYGVXBBWLCQABFwb8Rgrt7vX3Btd7e/1RdFihhRlbQbWWMyRb6oZyobnD8/IusqMKZl0tLQQ5rst//YjVDdQ2wW0+ns28gNVQD2L3G1vQHuFUZJuvY+VhCRh7MV7HztgBPPsxXsTObgK8K8Bk7W6CmAjCfxdb7ArhTzwCL7WbzNQFKI4VZDpZLDm7GUoeEvqRihn/VUNK4xZn3oIOBx0nqWU6Ok7yOOgbHDHQUlXGqaZ00ce51NjJYfqSzoI7V0EXbgN/TRTEF2bQSCvCopGGqVFRSupYPdaX9pGtBoKM/ihYstbA+anMAAAAASUVORK5CYII=",
    mapPointBlueIcon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMBAMAAACkW0HUAAAAGFBMVEUAAAAAkdoAkdoAkdoAkdoAkdoAkdoAkdq31JYNAAAAB3RSTlMA6rewcB05qbbEZgAAADVJREFUCNdjYAhRFHJlYGAzLy8vTmAILgcCUwZ3EFXCoA6iihjEQVQhlIIKQpVANUC1Qw0DAB6qGT3ytIVRAAAAAElFTkSuQmCC",
    mapPointOrangeIcon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMBAMAAACkW0HUAAAAGFBMVEUAAAD1giD1giD1giD1giD1giD1giD1giAHO3+/AAAAB3RSTlMA6rewcB05qbbEZgAAADVJREFUCNdjYAhRFHJlYGAzLy8vTmAILgcCUwZ3EFXCoA6iihjEQVQhlIIKQpVANUC1Qw0DAB6qGT3ytIVRAAAAAElFTkSuQmCC",
    noPhotoBig: "https://nhs-static-secure.akamaized.net/GlobalResources14/Default/images/no_photo/no_photos_325x216.png",
    noPhotoSmall: "https://nhs-static-secure.akamaized.net/GlobalResources14/Default/images/no_photo/no_photos_150x100.png",
    noPhotoListing: "https://nhs-static-secure.akamaized.net/GlobalResources14/Default/images/no_photo/no_photos_180x120.png",
    school_nearby_icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAYCAMAAAAmopZHAAAAwFBMVEUAAAAzksw1lc8wlM8wk80ylM43ls41lc41lc49mtA5mM+NxOMvks2ezOj////O5vPR5/Sayue62+/I4/K52u6w1uzT6PXr9fr0+f37/f6l0Omz1+2Ux+XH4vKXyebP5vR3uN7i8Pjk8fno8/rq9PqLwuPx+PyLw+P5/P76/P6NxOSOxORjrtmYyeaVyOaWyObf7vff7/i12O2GwOKJwePE4PGZyufu9vuPxOTz+fzK4/LL5PLL5POby+f8/v9rstv4VbFhAAAADnRSTlMAIzBKhLHR7vH09Pn7/D5jm6EAAADoSURBVHhenZHXjsMgFETXicF2bAb3lt7r9t7//68WX6KgVSSvtOcF6YCG4XLxBx2bO4HD7c4vazHPl0VVSN9jltFdV2yh2Qq3e9K9EIawd9ywXNJmw9VRTIDIXjIQglETj7Lnyxz5ck53eE0r24eiH20AbKI+FL6tPJfAYh1iNX4crxCuF4DkyjsFMCnLfZ3kSb0vXydA4SgfVMDsYXCLhpvBYQZUgT6v2E3JT3dPoPM6H0jf0jiO0/f0Ezr/2GeYgJZhRH1M/+x6dDm6yr6+P6i/fq/h+f4OgrXO53yeLfNv+a+z//0PP4HWGW4tdrFtAAAAAElFTkSuQmCC",
    shopping_nearby_icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAYCAMAAAAmopZHAAAAqFBMVEUAAAAzksw1lc8wlM8wk80ylM43ls41lc41lc49mtA5mM+NxOMvks2ezOjJ4/LO5vPr9fr///+IweK83O9irdlytd3R5/R9u+D6/P77/f6YyeaGwOJ8ut8ylM1jrtnV6fVmr9pOo9Sayud4ud+RxuWUx+VosNtrstuizumk0Omt1OtYqNfB3/DF4fFcqthgrNlQpNWFv+Ln8/no8/pXp9ZXp9eLwuOMw+PYxQFUAAAADnRSTlMAIzBKhLHR7vH09Pn7/D5jm6EAAADKSURBVHhenZHXzsMgDIWblJUQVna69/z3fP83qwNVqwgplXqu7A9jDvbggUJEaEIJCjs0wBFXutGKRzi44yETtXGqBRvecFyau8r4ehCwFs+Lxf5U2wPmWmHRZpPv4vCf2hsCWycRVL1ms9F4+5dn2Qu8EbWuEIeSncylXG3kRzqFjCPgREFUmZ8vcxyZ9fsbZIoAp9pyp8pGmgJPGo83SU89UR63/RH3OEfOf4c7/+6/XS7wbT7nsdPy89fOp2ee/vz79uXv9zldAJvvGPPrPGO3AAAAAElFTkSuQmCC",
    entertainment_nearby_icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAYCAMAAAAmopZHAAABFFBMVEUAAAAzksw1lc8wlM8wk80ylM43ls41lc41lc49mtA5mM+NxOMvks2ezOj////7/f76/P7O5vPR5/Ta7Pbm8vnr9frz+fzG4fFdq9hztt3Q5vRWp9bV6fV1t97i8Piayufo8/phrdnH4vJvtNyPxeSUx+X9/v/I4/LM5fP8/v+Zyudcqtiby+edzOejz+mk0Omn0eqs1Ouz1+202O273O+/3vDA3vDC3/DD4PFXp9ZgrNlhrNlXp9dirdllr9ppsdvU6fVsstzW6vXZ6/Zts9zb7fff7/hYqNfj8Pjj8fnl8vlZqNdaqdd2t97s9fvs9vvv9/vw9/zx+Px+vOD0+f32+/34/P5/vOCCvuGEv+GGwOKMw+P6DygNAAAADnRSTlMAIzBKhLHR7vH09Pn7/D5jm6EAAAEASURBVHhenZHVbsRADEWb3eAmuTNBWOYyMzMz0///Ryd1tVG1Uir1PB5b1rU98gcFWdUsTZULv6ykGKYTsMAxDUXKdFG3OQhu68WBLrnIcEs/BUknPSjoNEqxlzdnHpBhK99JDN65mNuoxJX1HnlupKlkEy0A/RdgH2Br7xFMWXjVwTaiMqqoPy5sXX26u3BU4bUAnafjc7xicR4pLQSa8BZD1+UnGMfRbRurHnbALOofSzA663u152lU/bAm+mk+kokQB5M+cOihPSXmUx6wxuVpUo/2mlE5jM9EHsoP4i5eum++NdL8tC8I3HzE1yu0b859hu+Zc/+cfw399z98AUf5Hvtmr1KSAAAAAElFTkSuQmCC",
    mapIconPwa: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAABCCAMAAAA14eWLAAABRFBMVEUAAAAAAAABAwMAAAABAgIAAAAAAAAAAAAAAAACBAcAAAAAAAAhcJ4hcJ4icqIhb50fa5UAAAAhcJ0hcJ4YTmsAAAAfapUcWoAgZ5IKHigCBAQAAAAhb50hb50ga5cgbZsfZpEcYIcbWX8ha5YZU3cYTm4faZQdXIEMHiscVHcbSGQgSGghb5wgb5sgbJkhbpwhbZohbJYaV3khapUPLD8PNkkfYokgZY4OKz0ZQlohcJ4tkcz///8phbsjeKkngbVJoNMskMksjscph70picAidKQkeqwqicKv1ewsj8phrNjZ6/UzlM4wks3j8fi83O93t95Am9ErjMUogrj1+fzS5/SZyubt9vtXp9YmfrPK4/I5l8/6/P6JweJ5ud4sjcYngrYle670+fzb7PbC3/DA3u+o0eqlz+mhzehmr9plrtlUpdW5WmofAAAAOnRSTlMAAgcECw8VJR8YExr69v7uzB3y6F8qyYNtPC8i3s6+uJuXjYR3amRbKiQXEOLUz7KTZmFURUJBPzYfH0kWSAAAA7ZJREFUeF6Vlld34jgAhVli3LBjDAkEUkmZzCSZ3q9tek3v09v28v/fV+VoV1gEzH2JOTpfvmtJtpUal59iSU2PoObm5tIs5CIZy6j0/vMn6yue43gr60+e76cZOx3be/rYhRT38dM9BVWx/a08gFo96lwOh5edqF4DkN/an4CykksFQkWtQEorImxhiRW+g0s/WCVYP1DSJ+jqg7QgFd3rArrtYGzaXRRej5NS7p6LWjO4I80a3HuUHMMBH4IJ+QAoJOVeubgKJuYK7itBSvNSUH2qszA6Q4TTVlELpqaGVY2QUlFtCd3mdLDZxZLGygrhXgHtIEHaKOwxpRBu8aJJym5RpRC+zUPdL7+H4a26h5B/S5XiDlXh8dn116/XgxNFye+SCzPriGLjnz6+ex8E79/9ehwbiLCe4UoitFy0Rocvjk4b9G/j9OZidKQF1yJKvoa78aaN018upP8Q67pL15I13UBdGqENbz6NdJZTxwbtSpvaa6O3+Nf1b8fyLP389+hNrtnaHAP1FXSkkfPPo3N5Mvh8Lv3sYEUXoIdLaST8I4jlz1D6cQmPg+mM5WAog4dx8FAGh3CsTJqD7mygK8DsAb6p4OAjyeBYAb/hICvAR+grYCM8ur09ChsK2McjAVpraI8ByfL9MwZsY83ioGZtoK6ClGkoINsAlpbmy/EMteRgDc/+W8f7DppJwSac+wK0/SLaScEIRd9mIJ3Wktw1/HJIcs7Bc3r9JZSbluikMpB09aQFOQtZbsiGPTni12fSYnikKQVZV7OKXpAoPVRN1jTFu75xk74e3Te8KX9Z6WYJ863pXGseJVPnLyvalSiNIn5MB3+gaBAhayqUL/NJPjr5l0woQLpfl3ccfJ/MfYezs0z3qfwNsLPGtoP6JK4OZ9vI2kIoPnO2b2wDvTtnqNUDtg3flj9zvKxuGjseup3xXKcLb8cw9f+LirKMfJG7+7iSe8E4VjRO+kZl0wN68QNSD/A2K4YvOIW0s8uL5So7kkUCi9iRrFpeXM7aglMOSJptmcZiuVR0IE4gV4BTLJUXDdOyNfWAJGZIy+hZglbKm5hvUq45j81yhWBZPaNJ86JKBZrja1pHTmCSTiU5SgsvECUTLtCSDItxipRZfYMomdDwmU3VqShfU6qkQr52KqaGkxZVUqFFuOmYtOmpkgr5pk4Y/pzlgBx9ijiXXOkvAAu+EM6iNB8+NIVwJqW1u2slF8qPtq6LxzZ5+BbiGyY1M0lCuVlBkdSMUbjkmYz9CwEewUrOARG6AAAAAElFTkSuQmCC",
    selected_school_nearBy_icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAYCAMAAAAmopZHAAAAAXNSR0IArs4c6QAAASBQTFRFAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQEBAQEBAgICAgICAgICAgICAgICAgICBAQEBQUFAQEBBAQEBQUFAQEBISEhISEhJiYmAAAANzc3ODg4QEBARkZGSEhISkpKTU1NUlJSVFRUVlZWV1dXWlpaXFxcX19fYWFhY2Njbm5ucHBwcnJydXV1dnZ2eHh4e3t7fX19hISEhYWFioqKjIyMj4+PkZGRlpaWn5+foKCgoqKio6OjpqamqKioq6urrKysra2tr6+vtLS0xMTEycnJ0NDQ4Xjy/gAAADN0Uk5TAAECAwQFBwgJCgsMDQ4PEBITFCYnMTIzNjtATleFh4qOs7fU1dbX8fL09PX19fb5+vz9ZtufqwAAAWFJREFUGBkFwdFS01AYhdFv/+ckJVApDoLIDINy4fs/kDPieCGiCKRpU5Kcs11LgACBwGDAIBBI56v2aPV2mN6ebDBCSGenXZNDrss89i82lpCu1seNAMDzfniwSZKuTteNAAClJpodpND7s3UCAAAi4wMp4uJdA8DHi9UOIMJbUvpw2gk4un1+1M24AGGNWW0j4LL9Xhn219MjqGmV8nkXrD6//k3rk2Z5iZt9gaUPdQG39293l2lKl3eH+1uIY2Vlwc8v8+8d8Hxy3f0AZYUWw/gtDIBjGMGLMmMTwNCeGDQPLVBHMtOSgeo/AJsqYJnInqoFT582WMwPXzevrpOzt11ugV8AsKrd67zbOiW5aQIAgPJvKPuXqaZQjZwCAADKoR9KTYFmEyEA8Lzv+1KcJDQvVRVwmadtvyvFTgKrHJa5iHm/G/q5LLVakiJSJElgu9RSq50xFUcNCexaq6vNf46ewPP8/fhrAAAAAElFTkSuQmCC",
    selected_shopping_nearBy_icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAYCAMAAAAmopZHAAAAAXNSR0IArs4c6QAAARRQTFRFAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQEBAQEBAgICAgICAgICAgICAgICAgICBAQEBQUFAQEBBAQEBQUFAQEBISEhISEhJiYmAAAANzc3ODg4QEBARkZGSEhISkpKTU1NXl5eY2NjbGxsd3d3eHh4fn5+goKCj4+PkpKSn5+foaGhoqKipqamqampqqqqrKysra2tsLCwsrKyuLi4urq6vLy8vb29ycnJzMzM0NDQ0tLS09PT1tbW3d3d4ODg4uLi4+Pj+/v79onLbwAAADN0Uk5TAAECAwQFBwgJCgsMDQ4PEBITFCYnMTIzNjtATleFh4qOs7fU1dbX8fL09PX19fb5+vz9ZtufqwAAATBJREFUGBldwduOEkEYhdFv/9XFoQcIZBzlwvd/K2+8MhqTsTl3ddWWYGIyrCVAgEBgMGAQCKTX+Wwxv13H228bjBDSdrPMXchtKpfh3cYS0n7VZ/Hgcj7+sEmS9ptVFv8o5cgnSKHddpUgf96tajVEh6+EYt0nYPdr+pO33KV+HUrp02YpFnlt0fUldxNhXUKzLHitcRta+7l/+QrKM4XmHTCVXEd7vPYF6GbqtAzu/A2O0L5/AaJXqBPP1Ck0mWeeFFwaz9qFYJx4No2Ex2Y+chsdPpwKH5XTwZ3bYZbS8o2Hl7cl9XJoVk7ddrtI/Fev7+9TTYGKiRAPLudhqNVJQmVqaoBrGQ/DqVY7Cax6nUoV5Xw6DqVOrVl3ESmSJLBdW23N7jANRwsJ7Naam81ffLipVYhtbUEAAAAASUVORK5CYII=",
    selected_entertainment_nearBy_icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAYCAMAAAAmopZHAAAAAXNSR0IArs4c6QAAAXRQTFRFAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQEBAQEBAgICAgICAgICAgICAgICAgICBAQEBQUFAQEBBAQEBQUFAQEBISEhISEhJiYmAAAANzc3ODg4QEBARkZGSEhISkpKS0tLTExMT09PUFBQUlJSVFRUXFxcXl5eYGBgYWFhZ2dnaGhoaWlpa2trbGxsbW1tbm5ub29vcHBwcXFxdHR0dXV1dnZ2eXl5enp6fHx8fX19fn5+f39/g4ODhISEh4eHkJCQkZGRlJSUlZWVmJiYm5ubnp6eoKCgo6Ojq6urrKyssLCwsbGxsrKytLS0tbW1t7e3uLi4wMDAwcHBxMTExsbGyMjIycnJy8vLzc3Nzs7O0dHR09PT1dXV1tbW2NjY2dnZ2tra29vbNH3rIwAAADN0Uk5TAAECAwQFBwgJCgsMDQ4PEBITFCYnMTIzNjtATleFh4qOs7fU1dbX8fL09PX19fb5+vz9ZtufqwAAAYdJREFUGBkFwUtPFEEYhtHn/aq6ZwbHAR2cACExEBIIrnRjwv/fuFEXGjAkLNDITRJopufS1VWf5wgQIBA4OOAgEEjTQT0crFfd+tEdHCGkrcmoiiYvfVo2T+64hLQz3qgEAJ4W81t3gqSdybgSAKBQWdVCML3ZGgcAAMAiviKYvXt99LGeZwAAzPyFqOmo2vuy/7leD/z2JwDVaPovqq4Ul5eXqjrOwHZnj9dVrahBBPbSw0Y3Wh8elIffJ9exVtTIcDWFw687T1dXANiGTFE8T9biG9t/d9mf4CjK1Dt379Pb4+GEtEmbQsZ7GcvCfX3051f96Ud/sW3L7YayJFTDOnI3+3Aw+T7L1Sjnk8vUtW30rrjy+TmQzy5uBqcvrZfONahmmzUAMDzeWt1c0z3fJ9Xx1XQcAAAA8vyx7YOpWAwGAAB51cxzCYaSYyYA8LRompw9SCj1RQXwnLqXps3ZPQhcedWnLNKinTcp96W4JJkFC5LA3XPJpbhHnIJbMQncSyle3PkP5tTHLcYTvMwAAAAASUVORK5CYII=",
    price_mobile_map_icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAAfCAMAAACMGl7YAAAATlBMVEUAAAAggJ8kbZkeZ5ghaZIha5Qea5Ydapcca5gdapYdaZYeaJUeaJQca5gca5kVdaoVdqsLgsELg8IeZ5MAkdoBkNoDjdMLgsAeZ5ICjtUgh+uyAAAAFHRSTlMACCMqPT6Bgr/D7vDw9PT19f39/WqWv1oAAAB4SURBVHhe7dS7EsIgGITRVTFB8c9Nbnn/FxXpYYalScFX72kXgNImNme0QmpagrfN+SAT8FxOS3WKgg6WLGgYz2JvEC1dvBQeeOCBu86g74aUdBwgZqGvt3z63yOv3Fo8/UqP91+77XUDUdKszXrPltQf3gL3uv0BzhZ6vgMnURMAAAAASUVORK5CYII=",
    price_selected_mobile_map_icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAAfCAMAAACMGl7YAAAARVBMVEUAAAAAAAAAAAAAAAAAAAAAAAAEBAQEBAQEBAQEBAQCAgICAgIFBQUXFxcuLi4tLS0AAABAQEBISEguLi4AAABDQ0NHR0cK39sVAAAAEXRSTlMACCMqPT6Bgr/D7vD09f39/SnAlTwAAAB0SURBVHhe7dSxEsIgEIThjSKid6J3QN7/Uc3Q40yWxoK/3q9dACFJPZ2kgKOoxfx0VjQCN21O1TQgFScrCWIsNkF1uvpXeOGFF546g7kbCjMHiDt/vePT/+S+2l/j0x93feZuHxuIDs3art/dklp4C1x+2y+2x22knF+5FwAAAABJRU5ErkJggg==",
    icon_location_circle: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOBAMAAADtZjDiAAAAAXNSR0IArs4c6QAAADBQTFRFAAAA////////////////////////////////9EM29EQ39VVJ93ty+aWe/NLO////p8xhuQAAAAl0Uk5TAA1Cgc/Q4PP+b35WCwAAAFJJREFUCNdjYGA0KXcWYGBgUPv//38SAwNT/5vd534oMIj9XTlz1v1EBvtXM2fOXPeZIf4kkJ7zhaF+J5Ce/R1O+0PFYepg+mDmwMxlYDQF2QMAiE407pycFL0AAAAASUVORK5CYII=",
    updateUserSessionPagination : "/updateUserSessionPagination",
    drivingDirectionsAction: "/drivingdirections",
    getCommunityMapCards: "/communities/getcommunitymapcards",
    newsletterSignup: "/newslettersignup",
    galleryUrl: "/detailgetgallery",
    getTrustBuilderBlockForSrpMobile: "/ajax/communities/gettrustbuilderblockforsrpmobile",
    BuilderShowcaseGalleryMoreResultsUrl: "/ajax/buildershowcase/getMoreImages",
    BuilderShowcaseGalleryGetFirstPageUrl: "/ajax/buildershowcase/getGalleryFirstPage",
    askQuestionUrl: "/freebrochure/requestaskquestion",
    stateCommunities: "ajax/buildershowcase/getCommunityList",
});
