define('nls/es/res',{
  "accept": "Aceptar",
  "accountBullet1": "Agrega casas y comunidades a tus favoritos",
  "accountBullet2": "Crea tus propias búsquedas inteligentes",
  "accountBullet3": "Sea el primero en conocer nuevas propiedades y promociones",
  "active": "Activos",
  "addMeToTheInterestList": "Agregarme a la Lista de Interesados",
  "address": "Dirección",
  "ageRestricted": "Restringido por edad",
  "alert": "Alerta",
  "all": "Todo",
  "allAmenities": "Todas las comodidades",
  "allAreas": "Todas las ciudades",
  "allBathrooms": "Todos los baños",
  "allBedrooms": "Todas las habitaciones",
  "allHomeTypes": "Todos los tipos de casas",
  "alreadyHaveAccount": "¿Ya tienes una cuenta?",
  "amenities": "Comodidades",
  "anAgentWillBeInContact": "¡Un agente le contactará pronto!",
  "appointment": "Cita",
  "aSiteErrorHasOccurred": "Ha ocurrido un error en el sitio web.",
  "askAQuestion": "Haga una pregunta",
  "askQuestion": "Hacer Pregunta",
  "at": "",
  "available": "Disponible",
  "awesomeThisIsABigStepDreamHome": "¡Increíble! ¡Este es un gran paso hacia la casa de tus sueños!",
  "bath": "Baño",
  "bathroom": "Baño",
  "bathrooms": "Baños",
  "baths": "Baños",
  "bed": "Cama",
  "bedroom": "Habitación",
  "bedrooms": "Habitaciones",
  "bedroomsHighLow": "# Habitaciones Mayor-Menor",
  "bedroomsLowHigh": "# Habitaciones Menor-Mayor",
  "beds": "Habitaciones",
  "brochure": "Folleto",
  "builderAToZ": "Constructor A-Z",
  "builderCommunity": "Comunidad de Constructor",
  "builderName": "Nombre del Constructor",
  "builders": "Constructores",
  "builderType": "Tipo de Constructor",
  "builderZToA": "Constructor Z-A",
  "by": "por",
  "call": "Llamar",
  "cancel": "Cancelar",
  "city": "Ciudad",
  "cityAToZ": "Ciudad A-Z",
  "cityZToA": "Ciudad Z-A",
  "clickBelowToGoThereNow": "Haga clic abajo para ir allí ahora.",
  "clickUpdateSeeResults": "Clic en Actualizar para ver los resultados",
  "close": "Cerrar",
  "collapse": "Ocultar",
  "comingSoon": "Próximamente",
  "comments": "Comentarios",
  "communities": "Comunidades",
  "community": "Comunidad",
  "communityAToZ": "Comunidad A-Z",
  "communityType": "Tipo de comunidad",
  "communityZToA": "Comunidad Z-A",
  "condoTownhome": "Condominio/Complejo",
  "createAccount": "Crear Cuenta",
  "createAccountAndSave": "Crear Cuenta & Guardar",
  "createAccountSubscribe": "Subscribirse para notificaciones por correo electrónico",
  "createAnAccountToGet": "Inicie sesión o cree su cuenta para:",
  "createFreeAccount": "Crea una cuenta GRATIS",
  "currentLocation": "Ubicación Actual",
  "custom": "Personalizado",
  "customBuilder": "Constructor Personalizado",
  "customBuildersMobileTab": "Constructoras <br/> Personaliz.",
  "customCommunitiesMobileTab": "Construya <br/> En Su Lote",
  "customHomesMobileTab": "Casas a su gusto",
  "dayNotAvailableToRequest": "La opción para agendar una cita hoy mismo no está disponible en línea. Para solicitar un recorrido hoy mismo, contacte al constructor",
  "default": "Por defecto",
  "delete": "Eliminar",
  "discoverMoreGreatCommunities": "Descubra otras grandiosas comunidades:",
  "discoverMoreNearbyCommunities": "Descubre más comunidades cercanas",
  "drivingDirections": "Indicaciones para llegar",
  "email": "Correo electrónico",
  "emailAddress": "Correo electrónico",
  "emailAddressIsNotValid": "El correo electrónico no es valido",
  "emailDoNotExist": "Lo sentimos, el correo electrónico que ha ingresado no concuerda con ninguna cuenta en el sistema. Por favor, vuelva a intentarlo",
  "enterPassword": "Escriba una contraseña",
  "enterYourEmailAddressReset": "Ingrese su dirección de correo electrónico para enviarle las instrucciones de reajuste de contraseña.",
  "event": "Evento",
  "fee": "Cuota",
  "filters": "Filtros",
  "firstAndLastName": "Nombre completo",
  "firstName": "Nombre",
  "for": "de",
  "forgotPassword": "¿Olvidó su contraseña?",
  "forYourConvenience": "Para su conveniencia, esta",
  "from": "desde",
  "fullNameValidation": "Por favor ingrese un nombre valido.",
  "gallery": "Galería",
  'garage': 'Garaje',
  "garages": "Garajes",
  "gated": "Cerrada",
  "gatedCommunity": "Comunidad Cerrada",
  "getBrochures": "Obtener Folletos",
  "getFreeBrochures": "Obtener Folletos Gratuitos",
  "getMapAndDirections": "Obtener el mapa y la dirección",
  "getMoreInfo": "Obtenga más información",
  "getMoreInformation": "Obtenga más información",
  "getTheFacts": "Obtenga toda la información",
  "getYourFreeBrochure": "Obtenga su folleto gratuito",
  "goBackToSignIn": "Volver a la pantalla de ingreso",
  "golfCourse": "Campo de Golf",
  "golfHomes": "casas de golf",
  "goToMyAccount": "Ir a Mi Cuenta",
  "goToYourAccount": "Ir a su cuenta",
  "green": "Ecológica",
  "greenFeatures": "Características Ecológicas",
  "hasBeenRemovedFromOurMailing": "ha sido removido de nuestras listas de correo. ¡Gracias!",
  "hasAlsoBeenSavedToYourAccount": "también ha sido guardada en su cuenta.",
  "hideAll": "Ocultar Todo",
  "hideHomeInfo": "Ocultar Información de la Casa",
  "home": "Casa",
  "homes": "Casas",
  "homeStatus": "Estado de la Casa",
  "homeType": "Tipo de Casa",
  "hotDeal": "Oferta",
  "images": "Imagenes",
  "in": "en",
  "including": "incluyendo",
  "invalidPhone": "Número telefónico inválido",
  "lastName": "Apellido",
  "learnAboutGrandOpening": "Aprenda más sobre la Gran Inaguración",
  'livingArea': 'Sala de estar',
  "livingAreas": "Salas de Estar",
  "locateMe": "Localizarme",
  "locations": "Ubicaciones",
  "loginAccountKeepme": "Mantenerme autenticado",
  "loginAccountTooltip": "Al seleccionarlo, lo autenticaremos automaticamente en su próxima visita.",
  "lots": "Lotes",
  "luxuryHomes": "Casas de Lujo",
  "manufactured": "Fabricado",
  "manufacturedHomes": "Casas Prefabricadas",
  "masterBedDownstairs": "Habitación Principal Abajo",
  "masterBedroom": "Habitación Principal",
  "masterBedUpstairs": "Habitación Principal Arriba",
  "matchingHomes": "# of Matching Homes",
  "max": "Máx",
  "mGS_HOMESEARCH_DEFAULT_ERROR_MSN": "Por favor introduzca una ubicación válida (ciudad, estado, código postal) o nombre de la comunidad.",
  "miles": "Millas del centro",
  "min": "Mín",
  "model": "Modelo",
  "modular": "Modular",
  "more": "Más",
  "moreInfo": "Mas información",
  "moreInformation": "Más información",
  "mSG_ACCOUNT_NO_EMAIL": "Introduzca una dirección de correo electrónico válida.",
  "mSG_AND": "y",
  "mSG_CREATE_ACCOUNT_INVALID_PASSWORD": "Su contraseña debe tener entre 7 y 15 caracteres, letras y dígitos solamente.",
  "mSG_HOME": "casa",
  "mSG_LOGIN_PASSWORD_SENT_TITLE": "Instrucciones para recobrar la contraseña enviadas",
  "mSG_PasswordsDontMatch": "Las contraseñas no coinciden",
  "mSG_REGISTER_INVALID_ZIP": "Código postal inválido",
  "name": "Nombre",
  "natureAreas": "Espacios Naturales",
  "newHome": "Casa Nueva",
  "newHomes": "Casas Nuevas",
  "nhsMobileApp": "NHS Aplicación Móvil",
  "noMax": "No Máx",
  "noMin": "No Mín",
  "noPrice": "Sin Precio",
  "noResultsFound": "No se encontraron resultados",
  "not": "¿No eres",
  "of": "de",
  "optional": "opcional",
  "options": "Opciones",
  "or": "o",
  "outsiteUS": "Fuera de EE. UU.",
  "overview": "Reseña",
  "page": "Pagina",
  "parks": "Parques",
  "password": "Contraseña",
  "passwordRequired": "Contraseña requerida",
  "phoneNumber": "Número telefónico",
  "photo": "Imagen",
  "photos": "Fotos",
  "place": "Lugar",
  "places": "Lugares",
  "plan": "Plan",
  "planNameAToZ": "Nombre del Plan A-Z",
  "planNameZToA": "Nombre del Plan Z-A",
  "plans": "Plans",
  "pleaseEnterCityStateOrZip": "Por favor introduzca ciudad/estado o código postal.",
  "pleaseEnterFirstAndLastName": "Por favor ingresa tu nombre y apellido",
  "pleaseTryAgainLater": "Por favor intenta de nuevo más tarde",
  "pool": "Piscina",
  "portfolio": "Portafolio",
  "prev": "Ant.",
  "price": "Precio",
  "priceHighLow": "Precio Mayor-Menor",
  "priceLowHigh": "Precio Menor-Mayor",
  "print": "Imprimir",
  "privacyPolicy": "Política de Privacidad",
  "provider": "Proveedor",
  "quality": "Calidad",
  "questions": "Preguntas",
  "questionToTheBuilder": "Le hemos enviado tu pregunta a la constructora.",
  "quickMoveIn": "Mudanza Rápida",
  "readLess": "Leer Menos",
  "readMore": "Leer Más",
  "register": "Registrarse",
  "required": "requerido",
  "resetYourPassword": "Reajustar tu Contraseña",
  "responded": "respondió",
  "responsiveness": "Responsabilidad",
  "review": "Reseña",
  "reviews": "Opiniones",
  "reviewTitle": "Titulo de la reseña",
  "save": "Guardar",
  "saved": "Guardado",
  "savedAsFavorite": "Guardada en Favoritos",
  "saveToFavorites": "Guardar en favoritos",
  "saveThisProperty": "Guardar esta propiedad",
  "see": "Ver",
  "seeMoreCommunities": "Ver Más Comunidades",
  "seeMoreHomes": "Ver más casas",
  "sendMessageToBuilder": "Enviar Mensaje al Constructor",
  "sendMeTheDetails": "Enviarme los detalles",
  "sendResetEmail": "Enviar Correo de Reajuste",
  "shareThis": "Compartir esta {0}",
  "showAll": "Ver Todo",
  "showLess": "Mostrar Menos",
  "showMore": "Mostrar Más",
  "showMoreHomes": "Mostrar Más Casas",
  "signIn": "Ingresar",
  "signInWithFacebook": "Iniciar sesión con Facebook",
  "singleFamily": "Para Una Familia",
  "sitTightTheBuilderWillReach": "¡Manténgase a la espera! El constructor se comunicará con usted para programar su cita.",
  "skip": "Saltar",
  "sold": "Vendido",
  "soldIn": "Vendido en el",
  "sortByResult": "Ordenado por",
  "specialOffers": "Ofertas Especiales",
  "sportFacilities": "Instalaciones Deportivas",
  "star": "Estrella",
  'story': 'Nivel',
  "stories": "Niveles",
  "storiesFloors": "Niveles/Pisos",
  "submit": "Enviar",
  "termsOfUse": "Condiciones de Uso",
  "text": "Texto",
  "thankYou": "¡Gracias!",
  "to": "a",
  "toThe": "en la",
  "tours": "Recorridos",
  "trustworthiness": "Integridad",
  "townhomes": "Casas de Ciudad",
  "update": "Actualizar",
  "userAlreadyExist": "El usuario ya existe.",
  "value": "Valor",
  "video": "Video",
  "videos": "Videos",
  "view": "Ver",
  "viewHomeInfo": "Ver Información de la Casa",
  "views": "Vistas",
  "waterfront": "Frente al Lago",
  "weHaveAlsoSavedThisProperty": "También hemos guardado esta propiedad en tu cuenta",
  "weHaveAlsoSavedThisPropertyAndBrochure": "También hemos guardado esta propiedad y folleto en tu cuenta",
  "your": "Su",
  "yourBrochureIsOnTheWay": "Su folleto está en camino",
  "yourBrochuresAreOnTheirWay": "Sus folletos están en camino.",
  "yourPasswordBetween5and20": "La contraseña debe tener entre 5 y 20 caracteres",
  "yourPasswordHasBeenSent": "La contraseña ha sido enviada a su correo electrónico.",
  "yourPasswordInstructions": "El password debe tener al menos 7 caracteres y contener letras y números.",
  "yourPasswordIsNotCorrect": "Lo sentimos, la contraseña que ingresó no es correcta. Revisa tu ortografía y vuelve a intentarlo.",
  "zipCode": "Código Postal"
});
