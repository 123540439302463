(function () {
  const refer = document.querySelector('#nhs_userSessionRefer');
  const httpRefer = document.querySelector('#nhs_userSessionHttpRefer');
  const source = document.querySelector('#nhs_utmSource');
  const medium = document.querySelector('#nhs_utmMedium');
  const campaign = document.querySelector('#nhs_utmCampaign');
  const term = document.querySelector('#nhs_utmTerm');
  const content = document.querySelector('#nhs_utmContent');
  const entranceUrl = document.querySelector('#nhs_nhsEntranceUrl');
  const googleClickId = document.querySelector('#nhs_googleClickId');
  const previousPageUrl = document.referrer;

  function checkCookie(name) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.indexOf(name + '=') === 0) {
        return cookie.substring((name + '=').length, cookie.length);
      }
    }
    return null;
  }

  const utmData = {
    nhsRefer: refer ? refer.value : '',
    gaUtmSource: source ? source.value : '',
    gaUtmMedium: medium ? medium.value : '',
    gaUtmCampaign: campaign ? campaign.value : '',
    gaUtmTerm: term ? term.value : '',
    gaUtmContent: content ? content.value : '',
    nhsEntranceUrl: entranceUrl ? entranceUrl.value : ''
  };

  if (dataLayer) {
    dataLayer.push(utmData);
  }
  let utmDataForCookie = '';
  if (source && source.value != '' && !previousPageUrl.includes(document.domain)) {
    utmDataForCookie = `httprefer=${httpRefer ? httpRefer.value : ''},` +
      `utmcsr=${source ? source.value : ''},` +
      `utmccn=${campaign ? campaign.value : ''},` +
      `utmcmd=${medium ? medium.value : ''},` +
      `utmctr=${term ? term.value : ''},` +
      `utmcct=${content ? content.value : ''},` +
      `nhsentranceurl=${previousPageUrl},` +
      `utmgclid=${googleClickId ? googleClickId.value : ''}`;
  } else if (previousPageUrl && !previousPageUrl.includes(document.domain)) {
    const browsers = [
      'google.com',
      'bing.com',
      'yahoo.com',
      'duckduckgo.com',
      'ecosia.com',
      'search.xfinity.com',
      'aol.com',
      'search.pch.com',
      'qwant.com'
    ];
    const browserUsed = browsers.find((item) => previousPageUrl.includes(item));
    if (browserUsed) {
      utmDataForCookie = `utmcsr=${browserUsed},` +
        'utmcmd=organic,' +
        `httprefer=${httpRefer ? httpRefer.value : ''},` +
        `nhsentranceurl=${previousPageUrl}`;
    } else {
      utmDataForCookie = `utmcsr=${previousPageUrl.replace(/http(s)?(:)?(\/\/)?(www\.)?/, '').replaceAll('/', '')},` +
        'utmcmd=referral,' +
        `httprefer=${httpRefer ? httpRefer.value : ''},` +
        `nhsentranceurl=${previousPageUrl}`;
    }
  } else {
    utmDataForCookie = 'utmcsr=(direct),utmcmd=(none),' +
      `httprefer=${httpRefer ? httpRefer.value : ''},`;
  }

  const cookieExist = checkCookie('__utmz');
  if (!cookieExist) {
    const utmzCookie = `__utmz=${utmDataForCookie}`;
    const domainCookie = 'domain=.newhomesource.com';
    const pathCookie = 'path=/';
    const cookie = [utmzCookie, domainCookie, pathCookie].join(';');
    document.cookie = cookie;
  }
}());

define("dataLayerUtm", function(){});

