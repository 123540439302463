// ReSharper disable all InconsistentNaming
(function (global, factory) {
    "use strict";

    if ("function" === typeof define && define.amd) {
        // If supports for AMD is available return the value to the AMD Loader
        // and to the global (window) object in case any JS module is expecting
        // the AppNexusModule to exists on the window object.
        define('appNexus',[], function () {
            return (global.AppNexus = factory(global));
        });
    } else if ("object" === typeof exports) {
        // If supports for Common JS is available export the module
        // and pass is value to the global (window) object in case non Common JS code 
        // is expected to work with this AppNexusModule.
        module.exports = (global.AppNexus = factory(global));
    } else {
        // If not support for AMD or Common JS is available register the module in the global scope.
        global.AppNexus = factory(global);
    }
}("undefined" !== typeof window ? window : {},
    function (root) {
        "use strict";
        /* In order to avoid AppNexus running twice or more. Flag is set to true once AppNexus ran.*/
        if (root.apntag) {
            return;
        }
        /**
         * 
         * Using module pattern to avoid unintended modifications to the constants values needed during the execution of the script. ECMAScript5 limitation not const word exists.
         * 
         * @constant 
         */
        var CONFIGURATION = (function () {
            // 
            var constants = {
                "ASTURL": "//acdn.adnxs.com/ast/ast.js", // URL were the AST JS resource is located.
                "PARAMETERSURL": "/GetAdParameters?url=", // URL of the AJAX call to get the parameters.
                "HTMLDIVPREFIX": "nhs_", // Prefix shared by the ID propert of all DIV elements where we are expecting an ad.
                "MEMBERID": 3232,
                "SKINADID": "nhs_Sponsorship"
            };

            var retrieveValue = function (constantName) {
                return constants[constantName];
            };

            return {
                get: retrieveValue // Expose a get method to return the value of the constants that have being defined in this module.
            };
        })();

        /**
         * @var Empty apntag.
         */
        var apntag;

        /**
         * @var Empty parameter object to stored the backend values passed as keywords to AppNexus.
         */
        var parameters;

        /**
         * @var Flag to indicate if the AST JS Library has already being loaded. If the AST Library has not being loaded value will be 'false', otherwise 'true'.
         */
        var isAstLoaded = false;

        /**
         * @var Flag to indicate if the request to load the AST JS Library has been started. If the AST Library has is being loaded value will be 'false', otherwise 'true'. 
         */
        var isAstLoading = false;

        /**
         * @var Flag to indicate if the AJAX request to load the parameters to being sent to AppNexus has being retrived from the server. If the parameters has been loaded value will be 'true', otherwise 'false'.
         */
        var areParametersDefined = false;

        /**
         * @event Custom event to indicate a new call for Ads at AppNexus must be executed.
         */
        var loadAdsEvent = document.createEvent("Event");

        /**
         * @event Custom event to indicate the AST JS library has been loaded.
         */
        var atsLoadedEvent = document.createEvent("Event");

        /**
         * @type {Function[]} List of functions to run after the Parameters get loaded
         */
        var onParametersLoaded = [];

        /**
         * @type {object} to store in memory the native ads templates.
         */
        var nativeAdTemplates = { };

        /**
         *
         * Executes an XML Http Request for a given URL.
         *
         * @param {string} url URL where the file being requested lives.
         */
        function requestHttpGet(url) {
            var request = new XMLHttpRequest();
            request.open('GET', url);
            request.send();
        }

        /**
         *
         * Method to insert a Native Ad into a page.
         *
         * @param {string} nativeAdDiv HTML Element ID where the Native Ad is going to be added.
         * @param {object} response AppNexus response with the Native Ad information.
         * @param {string} template HTML template that will be used to create the ad.
         */
        function createNativeAd(nativeAdDiv, response, template, isMobileDevice) {
            if (!response || !response.native) {
                return;
            }

            var fragment = document.createRange().createContextualFragment(template);
            var nativePosition = document.getElementById(nativeAdDiv);
            var headerDiv = fragment.querySelector("#nhs_adsAdvertisementIframe");
            var sponsoredMobile = fragment.querySelector("#nhs_nativeSponsorIframe");
            var regex = /(?:<script.+?)(?:src=")(.+?)".*?><\/script>/g;

            if (nativePosition) {
                fragment.querySelector("#nhs_nativeImgIframe").src = response.native.image.url;
                fragment.querySelector("#nhs_nativeLinkIframe").href = response.native.clickUrl;

                if (typeof response.native.cta === 'undefined') {
                    fragment.querySelector("#nhs_nativeLinkIframe").text = "Learn More About " + response.native.sponsoredBy;
                } else {
                    fragment.querySelector("#nhs_nativeLinkIframe").text = response.native.cta;
                }

                fragment.querySelector("#nhs_nativeHeadlineIframe").textContent = response.native.title
                fragment.querySelector("#nhs_nativeDescriptionIframe").textContent = response.native.body;
                fragment.querySelector("#nhs_nativeBuilderLogoIframe").src = response.native.icon.url;

                if (headerDiv) {
                    headerDiv.textContent = "Sponsored by " + response.native.sponsoredBy;
                }

                if (sponsoredMobile) {
                    sponsoredMobile.textContent = "Sponsored by " + response.native.sponsoredBy;
                }
          
                var impressionTrackers = response.native.impressionTrackers || [];
                var clickTrackers = response.native.clickTrackers || [];
                var jsTrackers = [];
                var result;

                var iframeForNativeAd = document.createElement("iframe");
                iframeForNativeAd.setAttribute('title', "Sponsored Ad");

                iframeForNativeAd.onload = function () {
                    var cssForIframe = isMobileDevice ? document.getElementById("NativeAdsMobileBundleCss").value : document.getElementById("NativeAdsDesktopBundleCss").value;

                    var fontLink = document.createElement('link');
                    fontLink.setAttribute('href', "https://fonts.googleapis.com/css?family=Muli&display=swap");
                    fontLink.setAttribute('rel', "stylesheet");

                    var iframeStyles = document.createElement('link');
                    iframeStyles.setAttribute('href', cssForIframe);
                    iframeStyles.setAttribute('rel', "stylesheet");

                    // impression trackers 
                    var i;
                    for (i = 0; i < impressionTrackers.length; i += 1) {
                        var image = new Image();
                        image.src = impressionTrackers[i];
                        image.style.display = "none";
                        iframeForNativeAd.contentWindow.document.body.appendChild(image);
                    }

                    // JS trackers
                    while ((result = regex.exec((response.native.javascriptTrackers))) !== null) {
                        jsTrackers.push(result[1]);
                    }

                    for (i = 0; i < jsTrackers.length; i += 1) {
                        var scr = document.createElement("script");

                        scr.type = "text/javascript";
                        scr.async = true;
                        scr.src = jsTrackers[i];
                        iframeForNativeAd.contentWindow.document.head.appendChild(scr);
                    }

                    iframeForNativeAd.contentWindow.document.head.appendChild(iframeStyles);
                    iframeForNativeAd.contentWindow.document.head.appendChild(fontLink);
                    iframeForNativeAd.contentWindow.document.body.appendChild(fragment);

                    bindEventsToNativeAdCard(iframeForNativeAd, nativePosition, clickTrackers, isMobileDevice);
                };

                nativePosition.appendChild(iframeForNativeAd);
                nativePosition.classList.add("loaded");
            }
        }

        /**
         * 
         * Method to clone an orignal object except for one property.
         * 
         * @private 
         * @param {object} original Object to be cloned.
         * @param {string[]} undesiredKeys name of the keys we do not want to exist on the cloned object.
         * @returns {object} a clone version of the original object with all properties except one.
         */
        var _omit = function (original, undesiredKeys) {
            var clonedObject = {};

            if (!Array.isArray(undesiredKeys))
                return original;

            for (var key in original) {
                if (!undesiredKeys.includes(key)) {
                    clonedObject[key] = original[key];
                }
            }

            return clonedObject;
        };

        /**
         * 
         * Executes a GET request via AJAX to the URL specified and executes the callback once the action has returned.
         * Parses the information obtained as a JSON before passing it to the callback function
         * 
         * @private
         * @param {string} url Location of the server where the GET action will be executed
         * @param {function} callback JavaScript function to execute if the AJAX request is succesfull.
         */
        var _ajaxRequest = function (url, callback) {
            var xmlHttpRequest = new XMLHttpRequest();

            xmlHttpRequest.onreadystatechange = function () {
                if (xmlHttpRequest.readyState === 4 && xmlHttpRequest.status === 200) {
                    var data;

                    try {
                        data = JSON.parse(xmlHttpRequest.responseText);
                    } catch (err) {
                        return;
                    }

                    // If callback function was passed execute it, once the AJAX has returned.
                    if ("function" === typeof callback && null !== data) {
                        callback(data);
                    }
                }
            };

            xmlHttpRequest.open("GET", url, true);
            xmlHttpRequest.setRequestHeader("X-Requested-With", "XMLHttpRequest");
            xmlHttpRequest.setRequestHeader("Cache-Control", "no-cache");
            xmlHttpRequest.setRequestHeader("Content-type", "application/json; charset=utf-8");
            xmlHttpRequest.send();
        };

        /**
         *
         * Handles the Ad Available event for the apntag.
         * Handles the render of the ad if the available ad is native, otherwise does nothing as the AST library handles the rendering of banner ads.
         * Handles the render of the ad if the available ad is native, otherwise does nothing as the AST library handles the rendering of banner ads.
         *
         * @private
         * @param {object} response Available ad information.
         */
        var _astAdAvailableHandler = function (response) {

            if (response.adType.indexOf("native") !== -1 && !!response.native && !!response.native.image && !!response.native.image.url) {
                var nativeAdDiv = response.targetId;
                var element = document.getElementById(response.targetId);
                var template = document.getElementById("nativeAd").innerHTML;
                const isMobileDevice = !!document.querySelector('[data-ismobiledevice="true"]');

                if (element) {
                  const innerTemplate = element.querySelector("[data-native-ad-template]");
                  template = innerTemplate ? innerTemplate.innerHTML : template;
                }

                if (nativeAdTemplates[response.targetId]) {
                  template = nativeAdTemplates[response.targetId];
                } else {
                  nativeAdTemplates[response.targetId] = template;
                }
              
                createNativeAd(nativeAdDiv, response, template, isMobileDevice);
            }
        };

        /**
         * 
         * Initialize the apntag object with the global page options.
         * 
         * @private 
         */
        var _astGlobalOptions = function () {
            apntag.setPageOpts({
                member: CONFIGURATION.get("MEMBERID"),
                disablePsa: true,
            });
        };
        /**
         * 
         * Define an Ad Tag to the apntag object.
         * 
         * @private
         * @param {String} divElementId div Element Id
         * @param {Number} width width
         * @param {Number} height height
         * @param {Number} index index
         */
        var _astDefineTag = function (divElementId, width, height, index) {
            var nativeLayouts = undefined;
            var allowedFormatsArray = ["banner"];
            var data = JSON.parse(parameters);
            var keywords = _omit(data, ["placements", "useLazyLoad"]);
            var element = document.getElementById(divElementId);
            var isNativeSupported = element.dataset.native === "true";
            var currentPlacement = data.placements.filter(function (el) {
                var elementId = divElementId.toLowerCase().replace(CONFIGURATION.get("HTMLDIVPREFIX"), "");
                return elementId.toLowerCase().indexOf(el.split("|")[1].toLowerCase()) > -1;
            });

            if ("undefined" === typeof currentPlacement || null === currentPlacement || 0 === currentPlacement.length)
                return;


            if (divElementId.indexOf("native") !== -1 || isNativeSupported) {
                allowedFormatsArray = ["native"];
                nativeLayouts = {
                    title: { required: true, max_length: 70 },
                    body: { required: true, max_length: 120 },
                    image: { required: false },
                    sponsoredBy: { required: true, max_length: 60 },
                    icon: { required: false },
                    cta: { required: false }
                }
            }

            currentPlacement = currentPlacement[0].split("|")[0];
            keywords.ref = ++index; // Ad ref parameter to Ad call.

            apntag.defineTag({
                member: CONFIGURATION.get("MEMBERID"),
                tagId: currentPlacement * 1, // Placement ID in console.
                sizes: [width, height],
                allowedFormats: allowedFormatsArray,
                targetId: divElementId,
                keywords: keywords,
                native: nativeLayouts,
                customIframeProps: {
                    title: 'Advertising'
                }
            });
            apntag.showTag(divElementId);
        };

        /**
         * 
         * Executes a request to App Nexus servers for new ads.
         * 
         * @private
         * @param {String} divElementId div Element Id
         * @param {Number} width width
         * @param {Number} height height
         * @param {Number} index index
         * @return {Function} Function with the parameters defined for an ad placement.
         */
        var _executeRequestToAppNexus = function (divElementId, width, height, index) {
            return function () {
                _astGlobalOptions();
                _astDefineTag(divElementId, width, height, index);
            };
        };


        /**
         * 
         * Search for HTML elements with the class "nhs_AstDiv" and execute a define call to AppNexus server for that position
         * 
         * @private     
         * @param {Boolean} overwriteAds Overwrite Ads flag
         * @param {String} selector Element class name or CSS Selector
         */
        var _createAppNexusRequest = function (overwriteAds, selector) {
            var index;
            var elementId;
            var element;
            var width;
            var height;
            var appNexusRequests = [];

            // We receive parameter named "selector" for load Ads regarding to class name sent from each View or PartialView (https://builderhomesite.atlassian.net/browse/BDXNHS-1569)
            var adElements = document.querySelectorAll(selector);
            var adElements = Array.prototype.slice.call(adElements);

            adElements = adElements.sort(function (a, b) {
                var indexA = a && a.id && a.id.indexOf("native") > 1;
                var indexB = b && b.id && b.id.indexOf("native") > 1;
                var widthA = 1000 - Math.round(a.getAttribute("data-width")) ;
                var heightA = Math.round(a.getAttribute("data-height"));
                var widthB = 1000 - Math.round(b.getAttribute("data-width")) ;
                var heightB = Math.round(b.getAttribute("data-height"));

                indexA = (indexA * 1000) + widthA + heightA;
                indexB = (indexB * 1000) + widthB + heightB;

                return indexB - indexA;
            });

            if (!adElements || 0 === adElements.length) return;

            var length = adElements.length;

            for (index = 0; index < length; index += 1) {
                element = adElements[index];
                elementId = element.id;
                width = Math.round(element.getAttribute("data-width"));
                height = Math.round(element.getAttribute("data-height"));

                if (0 === width && 0 === height && elementId !== CONFIGURATION.get("SKINADID")) {
                    continue;
                }

                // Ignore Ad containers that already have some content inside them, and ignore any element hidden via CSS.
                // Making an exception for Skin Ad. The offsetParent is null for elements with position "Fixed" as its the case of the Skin Ad.
                if ((!!element.querySelector('iFrame') && !overwriteAds) || (element.offsetParent === null && elementId !== CONFIGURATION.get("SKINADID"))) {
                    continue;
                }

                appNexusRequests.push(_executeRequestToAppNexus(elementId, width, height, index));
            }

            length = appNexusRequests.length;
            for (index = 0; index < length; index += 1) {
                apntag.anq.push(appNexusRequests[index]);
            }

            // All tags have been sent to AppNexus, ask AppNexus to load all the ads at once.
            apntag.loadTags();
        };
        /**
         * 
         * Indicates the AST JS Library is already loaded, and trigger the loadAdsEvent.
         */
        var _astLoadedCallback = function () {
            isAstLoaded = true;
            isAstLoading = false;
            apntag = root.apntag;

            if (!apntag)
                return;

            apntag.onEvent("adAvailable", _astAdAvailableHandler);
            root.dispatchEvent(loadAdsEvent);
        };

        /**
         * 
         * Attachs an event to the object, checks the browser supports to choose the better option to handle the load event.
         * 
         * @private 
         * @param {object} object - Object where the load event will be attached.
         * @param {function} event - Function that will be executed when the event load for the object is triggered.
         */
        var _attachEventToLoad = function (object, event) {
            if (object.addEventListener) {
                object.addEventListener("load", event, false);
            } else if (root.attachEvent) {
                object.attachEvent("onload", event);
            }
        };

        /**
         * 
         * Load the AST Script async.
         *
         * @private 
         * @param {function} callback - Function to execute when the load of the AST.js file has finished.
         */
        var _loadAstScriptAsync = function () {
            if (isAstLoaded) {
                // AST JS Library has already been loaded. Do not load it again.
                return;
            }

            var tag = document.getElementsByTagName("meta")[0];
            var script = document.createElement("script");

            _attachEventToLoad(script,
                function () {
                    root.dispatchEvent(atsLoadedEvent);
                });
            script.async = true;
            script.src = document.location.protocol + CONFIGURATION.get("ASTURL");
            script.type = "text/javascript";
            tag.parentNode.insertBefore(script, tag);
            isAstLoading = true;
        };

        /**
         * 
         * Callback function to be executed when the AJAX to retrieve the Ad Parameters has returned. Stores the data in the global parameters variable and changes the value of the areParametersDefined flag to true.
         * 
         * @private 
         * @callback 
         * @param {object} data JSON object with the parameters.
         */
        var _adParametersLoadedCallback = function (data) {
            parameters = data;
            areParametersDefined = true;

            for (var i = 0; i < onParametersLoaded.length; i++) {
                var callback = onParametersLoaded[i];
                if (typeof callback === "function") {
                    callback();
                }
            }
            onParametersLoaded = [];
        };
        /**
         * 
         * Callback function to be executed when the AJAX to retrieve the Ad Parameters and refresh the parameters.
         * 
         * @private 
         * @callback 
         * @param {object} data JSON object with the parameters.
         */
        var _adParametersLoadedAndRefreshCallback = function (data) {
            parameters = data;
            areParametersDefined = true;
            for (var i = 0; i < onParametersLoaded.length; i++) {
                var callback = onParametersLoaded[i];
                if (typeof callback === "function") {
                    callback();
                } 
            }
            onParametersLoaded = [];
            if ("undefined" === typeof apntag ||
                null === apntag ||
                "function" !== typeof apntag.refresh ||
                !areParametersDefined) {
                return;
            }

            let adsIds = getIdsFromElements(document.querySelectorAll(".nhs_ASTAboveDiv"));
            adsIds = adsIds.concat(getIdsFromElements(document.querySelectorAll(".nhs_ASTBelowDiv")));
            let keywords = JSON.parse(parameters);
            delete keywords["placements"];
            delete keywords["useLazyLoad"];
            apntag.clearRequest()
            for (var i = 0; i < adsIds.length; i++) {
                apntag.setKeywords(adsIds[i], keywords);
                document.getElementById(adsIds[i]).innerHTML = "";
            }
            _createAppNexusRequest(true, ".nhs_ASTAboveDiv");
            _createAppNexusRequest(true, ".nhs_ASTBelowDiv");
            _createAppNexusRequest(true, ".nhs_ASTListViewLastElements");
        };

        /**
         * 
         * Initialize and executes the process to load the Ads from AppNexus.
         *
         * @private 
         */
        var _loadAds = function () {
            var url = CONFIGURATION.get("PARAMETERSURL") + escape(window.location.href) + "&_=" + new Date().getTime();
            const params = document.querySelectorAll('[data-ads-parameter]');
            for (let i = 0; i < params.length; i++) {
                var value = params[i].value;
                var name = params[i].dataset.paramname;
                url = url + `&${name}=${value}`;
            }
            var skinAd = document.getElementById(CONFIGURATION.get("SKINADID"));

            if ("undefined" !== skinAd && null !== skinAd) {
                document.body.insertBefore(skinAd, document.body.firstChild);
            }

            if (document.querySelectorAll(".nhs_ASTAboveDiv, .nhs_ASTBelowDiv").length === 0) {
                // The page does not have any ad container defined in the HTML.
                return;
            }

            // Trigger an AJAX Request to obtain the parameters we need to pass into AppNexus calls while we wait until the AST Library is ready.
            _ajaxRequest(url, _adParametersLoadedCallback);

            if (isAstLoaded) {
                // The AST JS library has been loaded we can make calls to AppNexus.
                // Trigger onLoadAdsEvent to execute the call to AppNexus and generate the Ads.
                root.dispatchEvent(loadAdsEvent);
            } else if (!isAstLoading) {
                // The AST JS library has not been loaded and there's not request to load it.
                // Trigger a request to load the AST JS library and add a request to load Ads in the adRequestsQueue.
                _loadAstScriptAsync(_astLoadedCallback);
            }
        };

        /**
         * 
         * Causes a new ad server call and refreshes the specific ad element on the page.
         * 
         * @public 
         * @param {string} divElementId The unique identifier of a specific ad slot. One or more identifiers can be specified in an array.
         */
        var refreshAd = function (divElementId) {
            if ("undefined" === typeof apntag || null === apntag || "function" !== typeof apntag.refresh || !areParametersDefined) {
                return;
            }

            apntag.refresh([divElementId]);
        };

        /**
         * 
         * Causes a new ad server call and refreshes all the ad elements on the page.
         * 
         * @public 
         */
        var refreshAllAds = function () {

            if ("undefined" === typeof apntag ||
                null === apntag ||
                "function" !== typeof apntag.refresh ||
                !areParametersDefined) {
                return;
            }

            var aboveElementIds = getIdsFromElements(document.querySelectorAll(".nhs_ASTAboveDiv"))
            var belowElementIds = getIdsFromElements(document.querySelectorAll(".nhs_ASTBelowDiv"))

            apntag.refresh(aboveElementIds);
            apntag.refresh(belowElementIds);
        };

        /**
        * 
        * Causes a new ad server call and refreshes all the ad elements on the page.
        * 
        * @public 
        */
        var refreshAllAdsAndUpdateParams = function () {
            var url = CONFIGURATION.get("PARAMETERSURL") + escape(window.location.href) + "&_=" + new Date().getTime();
            const params = document.querySelectorAll('[data-ads-parameter]');
            for (let i = 0; i < params.length; i++) {
                var value = params[i].value;
                var name = params[i].dataset.paramname;
                url = url + `&${name}=${value}`;
            }
            _ajaxRequest(url, _adParametersLoadedAndRefreshCallback);
        };

        /**
         *
         * Get the element Id from Array.
         *
         * @param {String} elements Array of elements.
         */
        var getIdsFromElements = function (elements) {
            return Array.prototype.slice.call(elements).map(function (element) {
                return element.id;
            });
        };

        /**
         * 
         * Delete an element from the AppNexus Call.
         * 
         * @param {String} tagId Identifier of the ad impression, usually the ID of the HTML container for the Ad. 
         */
        var deleteAdOnDemand = function (tagId) {
            if (apntag && apntag.requests && apntag.requests.tags) {
                delete apntag.requests.tags[tagId];
            }
        };

        /**
         * 
         * Delete an element from the AppNexus Call.
         * 
         * @param {String} tagId Identifier of the ad impression, usually the ID of the HTML container for the Ad. 
         */
        var deleteAdsOnDemand = function (tagsId) {
            if (apntag && apntag.requests && apntag.requests.tags) {
                Array.prototype.map.call(tagsId, function (tagId) {
                    delete apntag.requests.tags[tagId];
                });
            }
        };

        /**
         *
         * Simplify the Ads creation in one only call
         *
         * @param {Boolean} param Decides if ad its going to be overwritten.
         * @public 
         */
        var generateAllAds = function (param) {
            param = typeof param === "undefined" ? true : !!param;

            if ("undefined" === typeof apntag || null === apntag || "function" !== typeof apntag.refresh || !areParametersDefined) {
                return;
            }

            _createAppNexusRequest(param, ".nhs_ASTAboveDiv");
            _createAppNexusRequest(param, ".nhs_ASTBelowDiv");
            _createAppNexusRequest(param, ".nhs_ASTListViewLastElements");
        };

        /**
         * 
         * Causes a new ad server call. This method should be used only when the elements where the ads are going to be displayed are added via JavaScript.
         *
         * @param {Boolean} overwrite overwrite
         * @param {String} selector selector
         * @public 
         */
        var regenerateAdCalls = function (overwrite, selector) {
            if ("undefined" === typeof apntag || null === apntag || "function" !== typeof apntag.refresh || !areParametersDefined) {
                return;
            }

            _createAppNexusRequest(overwrite, selector);
        };

        /**
         *
         * Creates a new ad request on demand to the given query selector expression passed as parameter.
         *
         * @param {String | HTMLElement[]} selector - query selector expression that will be used to find the ad(s) we want to create on demand.
         * @public 
         */
        var createAdOnDemand = function (selector) {
            var index;
            var elementId;
            var element;
            var width;
            var height;
            var appNexusRequests = [];

            //This is to validate if the global variable is undefined and if contains the push method.
            if ("undefined" === typeof apntag || null === apntag || "function" !== typeof apntag.anq.push) {
                return;
            }

            // If the parameters has not being defined call the function itself to wait for the AJAX to get the parameters to end.
            if (!areParametersDefined) {
                onParametersLoaded.push(function () {
                    _createAppNexusRequest(false, selector);
                });
                return;
            }

            if (typeof selector !== "string" && !Array.isArray(selector)) return;

            var adElements = Array.isArray(selector) ? selector : document.querySelectorAll(selector);

            if (!adElements || 0 === adElements.length) return;

            var length = adElements.length;
            var randomNumber = Math.floor(Math.random() * 2000) + 100;

            for (index = 0; index < length; index += 1) {
                element = adElements[index];
                elementId = element.id;
                width = Math.round(element.getAttribute("data-width"));
                height = Math.round(element.getAttribute("data-height"));

                if (0 === width && 0 === height) continue;

                // HACK: Index parameter could be tracked in AppNexus code to identify each ad so we are using the index plus a random base to avoid collisions.
                // This code can be improved.
                appNexusRequests.push(_executeRequestToAppNexus(elementId, width, height, index + randomNumber));
            }

            length = appNexusRequests.length;

            for (index = 0; index < length; index += 1) {
                apntag.anq.push(appNexusRequests[index]);
            }

            // All tags have been sent to AppNexus, ask AppNexus to load all the ads at once.
            apntag.loadTags();
        };

        /**
         *
         * Deletes an ad tag previously requested from the AppNexus manager. This method prevents tags removed from the HTML to be requested to the ads server.
         * @param {String} selector a string containing one or more selectors to match. This string must be a valid CSS selector string.
         */
        var deleteAdsBySelector = function(selector) {
            var nativeIds = [];
            var elements = document.querySelectorAll(selector);
            
            if (elements && elements.length > 0) {
                Array.prototype.map.call(elements, function (div) {
                    nativeIds.push(div.id);
                });
            }

            if (nativeIds.length > 0) {
                deleteAdsOnDemand(nativeIds);
            }
        }

        // Attach an event listener to the root object (window) for the custom onLoadAdsEvent event.
        loadAdsEvent.initEvent("onLoadAdsEvent", false, false);

        // Initial load with default parameters for _createAppNexusRequest, related to ticket https://builderhomesite.atlassian.net/browse/BDXNHS-1569
        root.addEventListener("onLoadAdsEvent", function () {
            var createRequestsCallback = function () {
                _createAppNexusRequest(false, ".nhs_ASTAboveDiv");
                _createAppNexusRequest(false, ".nhs_ASTBelowDiv");
                _createAppNexusRequest(false, ".nhs_ASTListViewLastElements");
            };

            if (!areParametersDefined) {
                onParametersLoaded.push(createRequestsCallback);
            } else {
                createRequestsCallback();
            }
        }, false);

        // Attach an event listener to the root object (window) for the custom onLoadAdsEvent event.
        atsLoadedEvent.initEvent("onAstLoadedEvent", false, false);
        root.addEventListener("onAstLoadedEvent", _astLoadedCallback, false);

        if ("function" === typeof define && define.amd && "complete" === document.readyState) {
            // If the module was loaded using RequireJS there will be a race condition with the windows onload event.
            _loadAds();
        } else {
            // On the load event of the root element (window) trigger a call to load Ads.
            _attachEventToLoad(root, _loadAds);
        }

        /**
         *
         * Attaches the click event to the native ad card of the SRP
         *
         * @public 
         */
        var bindEventsToNativeAdCard = function (iframeForNativeAd, nativePosition, clickTrackers, isMobileDevice) {
            var iframeBody = iframeForNativeAd.contentWindow.document.body;

            var onBeginTouch = function (e) {
                iframeBody.addEventListener("touchend", clickAdFunction);
            }

            var onMove = function (e) {
                iframeBody.removeEventListener('touchend', clickAdFunction);
            };

            var clickAdFunction = function (e) {
                e.preventDefault();
                for (var j = 0; j < clickTrackers.length; j += 1) {
                    requestHttpGet(clickTrackers[j]);
                }

                var url = iframeBody.querySelector("#nhs_nativeLinkIframe").href;
                window.open(url, "_blank");
                

                return false;
            };

            if (!isMobileDevice) {

                iframeForNativeAd.addEventListener("mouseenter",
                    function () {
                        iframeBody.classList.add("nhs_Hover");
                    });

                iframeForNativeAd.addEventListener("mouseleave",
                    function () {
                        iframeBody.classList.remove("nhs_Hover");
                    });

                iframeBody.addEventListener("click", clickAdFunction);

            } else {

                iframeBody.addEventListener("touchstart", onBeginTouch);
                iframeBody.addEventListener("touchmove", onMove);

            }
        }

        return {
            refreshAds: refreshAllAds,
            refreshAdsAndUpdateParams: refreshAllAdsAndUpdateParams,
            refreshAdByName: refreshAd,
            regenerateAds: regenerateAdCalls,
            generateAllAds: generateAllAds,
            loadAdOnDemand: createAdOnDemand,
            deleteAdByTagName: deleteAdOnDemand,
            deleteAllAdsBySelector: deleteAdsBySelector,
        };
    }));
