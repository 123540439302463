/**
 * This module is to load scripts in NHS site.
 * The idea is to use the tag <script-component></script-component> to download this resource.
 *
 * The attributes supported are:
 * src: where the resource URL is downloaded.
 * strategy:  when to load your.
 *
 * Strategies that can be used:
 * lazyOnload: Load during idle time
 *
 * Example:
 * <script-component src="main.js" strategy="lazyOnload">
 *
 */
(function scriptComponent(global) {
  'use strict';

  /**
   * Class represeting a script tag.
   */
  class ScriptComponent extends HTMLElement {
    /**
     * loadScript: fetch the script to download.
     */
    loadScript() {
      const script = document.createElement('script');
      script.src = this.getAttribute('src');
      script.defer = true;
      document.body.appendChild(script);
      script.remove();
    }

    /**
     * lazyOnload: download the script after document is complete.
     */
    lazyOnload() {
      if (document.readyState === 'complete') {
        this.loadScript.call(this);
      } else {
        global.addEventListener('load', () => {
          this.loadScript.call(this);
        });
      }
    }

    /**
     * connectedCallback: Executed each time the tag is inserted.
     */
    connectedCallback() {
      const strategy = this.getAttribute('strategy');
      if (this[strategy]) {
        this[strategy]();
        return;
      }

      this.loadScript();
    }

    /**
     * constructor: Create a ScriptComponent
     */
    // eslint-disable-next-line no-useless-constructor
    constructor() {
      super();
    }
  }

  customElements.define('script-component', ScriptComponent);
}(window));

define("scriptComponent", function(){});

