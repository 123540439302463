require(["dataLayerUtm", "routes", "jquery", "search", "i18n!nls/res", "lazyLoad", "appNexus", "favoritesIcon", "windowModal", 'auth0DomEventHandler', 'pixelTracker', 'scriptComponent'], function (dataLayerUtm, routes, $, Search, res, lazyLoad) {
    var isTabletDevice = document.getElementById("nhs_IsTabletDevice");

    if (isTabletDevice.value === "True") {
        require(["menuEventsTablet"], function () { });

    } else {
        
        function loadDesktopEvents(event) {
            if (event.which === 9) {
                require(["menuEventsDesktop"], function () { });
                document.removeEventListener("keydown", loadDesktopEvents);
            }

        }
        document.addEventListener("keydown", loadDesktopEvents);
  }

    var requireJsLoadedEvent = new Event("requireJsLoaded");
    document.dispatchEvent(requireJsLoadedEvent);

    lazyLoad.init();
});
define("main", function(){});

