(function modalLoader(global) {
  'use strict';

  global.NewHomeSource = global.NewHomeSource || {};
  global.NewHomeSource.ModalLoader = {};

  /**
   * @private
   * createBackGroudModal: create only the background modal.
   *
   */
  function createBackGroudModal() {
    const modalBackground = document.querySelector('body >.modal_background');

    if (!modalBackground) {
      const element = document.createElement('div');
      element.id = 'modal';
      element.classList.add('modal_background');
      element.setAttribute('data-modal-close-element', '');
      element.setAttribute('tabindex', '-1');
      element.style.height = document.documentElement.offsetHeight + 'px';
      document.body.appendChild(element);
    }
  }

  /**
   * Creates the loading animation when trying to load a modal.
   */
  function createLoadingAnimation() {
    const loading = document.createElement('div');
    loading.id = 'modal-loading';
    loading.classList.add('nhs_Loading');
    loading.style.display = 'block';
    document.body.appendChild(loading);
  }

  /**
   * Used to delete the loading animation
   */
  function deleteLoadingAnimation() {
    document.querySelector('#modal-loading').remove();
  }

  /**
   * @private
   * Removes background modal.
   * Modals using micromodal component have its own background overlay.
   */
  function removeBackgroundModal() {
    const modalBackground = document.querySelector('.modal_background');

    if (modalBackground) {
      modalBackground.remove();
    }
  }

  /**
   * Used to determine the height of the document and set it to the modal background.
   * @param {*} fragment the HTML fragment.
   */
  function setA11yDialogBackgroundHeight() {
    const background = document.querySelector('.modal_background');

    if (background) {
      background.style.height = document.documentElement.offsetHeight + 'px';
    }
  }

  /**
   * Used to load the modal with the new A11y-dialog component
   * @param {*} fragment the HTML element to be added to the dom
   */
  function loadA11yModal(fragment, triggerElement) {
    deleteLoadingAnimation();
    removeBackgroundModal();

    if (triggerElement) {
      triggerElement.focus();
    }

    const element = fragment.querySelector('[data-a11y-dialog]');
    const body = document.body;
    body.appendChild(fragment);

    setA11yDialogBackgroundHeight();

    global.openedModal = new global.A11yDialog(element);
    global.openedModal.show();
  }

  /**
   * Used to load the modal the old fashioned way.
   * @param {*} fragment the HTML element to be added to the dom
   */
  function loadVanillaModal(fragment) {
    const element = document.createElement('div');
    element.id = 'modal-content';
    element.classList.add('modal_div');
    element.setAttribute('aria-hidden', true);

    const modalLoading = document.querySelector('#modal-loading');
    if (modalLoading) {
      modalLoading.remove();
    }
    element.appendChild(fragment);
    const backgroundElement = document.querySelector('.modal_background');
    backgroundElement.after(element);
  }

  /**
   * @private
   * createModalContent: create the body of the modal with the HTML.
   *
   * @param {String} htmlData to be inner in the HTML.
   */
  function createModalContent(fragment, triggerElement, useA11yDialog) {
    if (useA11yDialog) {
      loadA11yModal(fragment, triggerElement);

      if (triggerElement) {
        triggerElement.setAttribute('data-is-a11y-modal', 'true');
      }
    } else {
      loadVanillaModal(fragment);
    }

    if (triggerElement) {
      triggerElement.setAttribute('data-is-modal-loaded', 'true');
    }
  }

  /**
   * Used to clear the contents of the modal-content element
   */
  function clearModalContent() {
    const modal = document.querySelector('#modal-content');

    if (modal) {
      modal.remove();
    }
  }

  /**
   * Used to display the modal background if required
   */
  function displayModalContainer() {
    const modalBackground = document.querySelector('#modal-content');

    if (modalBackground) {
      modalBackground.classList.remove('hidden');
    }
  }

  /**
   * Used to display the modal background if required
   */
  function displayBackgroundModal() {
    const modalBackground = document.querySelector('.modal_background');

    if (modalBackground) {
      modalBackground.classList.remove('hidden');
    }
  }

  /**
   * Use the fetch API to get the HTML modal content from the server
   * @param {string} url
   * @returns the HTML as a raw string
   */
  async function fetchModalContent(url) {
    const request = await fetch(url, {
      method: 'GET',
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    });

    const response = await request.text();
    const fragment = document.createRange().createContextualFragment(response);
    return fragment;
  }

  /**
   * @private
   * loadModal: request the modal to the server.
   *
   * @param {String} url to request the modal.
   * @param {Function} callback callback to execute after the server respond.
   * @param {} triggerElement is element that triggers the request of modal.
   */
  async function loadModal(url, callback, triggerElement) {
    createBackGroudModal();
    clearModalContent();
    displayBackgroundModal();
    createLoadingAnimation();

    const fragment = await fetchModalContent(url);

    // determine if we should use the a11y-dialog to open the modal or not.
    const useA11yDialog = fragment.querySelector('[data-a11y-dialog]') !== null;
    createModalContent(fragment, triggerElement, useA11yDialog);

    // execute the callback if provided and is a valid function
    if (typeof callback === 'function') {
      callback();
    }
  }

  /**
   * We use the data-is-modal-loaded attribute to let know the windowModal component
   * that the modal from a button is already loaded and it should only display it instead
   * of going to the server to load it again when clicking the button that opens the modal.
   * If we want to load the modal again (for example, the modal was modified or we want to open a different modal),
   * we should remove the data attribute.
   */
  function resetIsModalLoadedFlag() {
    const isModalLoadedElem = document.querySelector('[data-is-modal-loaded]');

    if (isModalLoadedElem) {
      isModalLoadedElem.removeAttribute('data-is-modal-loaded');
    }
  }

  /**
   * @private
   * Gets the modal from the server with an ajax,
   * if it exists and hidden, then just displays it.
   * @param {string} url The action url.
   * @param {function} functionToExecute A callback function to run after getting the modal.
   * @param {object} event The event object.
   */
  function showModal(url, functionToExecute, element) {
    if (!url) {
      return;
    }

    const isElement = element instanceof HTMLElement;
    const isModalLoaded = isElement ? element.getAttribute('data-is-modal-loaded') === 'true' : false;
    const isA11yModal = isElement ? element.getAttribute('data-is-a11y-modal') === 'true' : false;

    if (isElement && isModalLoaded) {
      if (isA11yModal) {
        global.openedModal.show();
      }
      displayModalContainer();
      displayBackgroundModal();
    } else {
      resetIsModalLoadedFlag();
      requestAnimationFrame(loadModal.bind(this, url, functionToExecute, element));
    }
  }

  /**
   * @private
   * openUrl: open the url.
   *
   * @param {Element} element triggers the request of modal.
   * @param {Function} callback to execute after the server respond.
   */
  function openUrl(element, callback) {
    const url = this.href || this.getAttribute('href') || this.alt || this.dataset.modalUrl;
    showModal(url, callback, element);
    this.blur();
  }

  /**
   * @private
   * hideModal: hide modal.
   *
   */
  function hideModal() {
    const modalContent = document.querySelector('#modal-content');

    if (modalContent) {
      modalContent.classList.add('hidden');
      modalContent.classList.remove('is-open');
    }

    const background = document.querySelector('.modal_background');

    if (background) {
      background.classList.add('hidden');
    }

    if (global.openedModal) {
      global.openedModal.hide();
    }
  }

  /**
   * @private
   * closeModal: close modal when close element is trigger it.
   *
   * @param {Event} event with the information associated to the opener event.
   */
  function closeModal(event) {
    const modalLoading = document.querySelector('#modal-loading');
    const useA11yDialog = event.target.closest('[data-a11y-dialog]') !== null;

    if (event.target.closest('[data-modal-close-element]') && !modalLoading) {
      hideModal();

      if (useA11yDialog) {
        if (global.openedModal) {
          global.openedModal.hide();
        }
      }
    }
  }

  /**
   * @private
   * getParameters: get all parameters from URL
   *
   * @param {String} query with the URL to get parameters.
   * @returns Object with the key and value parameters.
   */
  function getParameters(query) {
    const params = {};
    if (!query) { return params; } // return empty object
    const pairs = query.split(/[;&]/);
    for (let i = 0; i < pairs.length; i += 1) {
      const keyVal = pairs[i].split('=');
      if (!keyVal || keyVal.length !== 2) {
        // eslint-disable-next-line no-continue
        continue;
      }
      const key = unescape(keyVal[0]);
      let val = unescape(keyVal[1]);
      val = val.replace(/\+/g, ' ');
      params[key] = val;
    }
    return params;
  }

  /**
   * @private
   * initModal: method to check if modal needs to be open
   *
   * @param {Event} event with the information associated to the event.
   */
  function initModal(event) {
    const element = event.target.closest('[data-load-modal]');
    if (!element) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    openUrl.call(element, element);
  }

  /**
   * @constructs NewHomeSource.ModalLoader
   */
  function ModalLoader() {
  }

  /**
   * @public
   * resizeModal: change the width and height of the modal.
   *
   * @param {Number} width new width of the modal.
   * @param {Number} height new height of the modal.
   */
  ModalLoader.resizeModal = function resizeModal(width, height) {
    const content = document.querySelector('#modal-content');
    const modalDiv = document.querySelector('.modal_div');
    if (content && modalDiv) {
      modalDiv.style.height = height;
      modalDiv.style.width = width;
    }
  };

  /**
   * @public
   * getModalResized: open modal and resize it.
   *
   * @param {String} url to request the modal.
   */
  ModalLoader.getModalResized = function getModalResized(url) {
    const modalContent = document.querySelector('#modal-content');
    if (modalContent) {
      const queryString = url.replace(/^[^?]+\??/, '').toLowerCase();
      const params = getParameters(queryString);
      const width = (params.width * 1) || 630; //  + 48 || 630;
      const height = (params.height * 1) || 440; //  + 40 || 440;

      const xhr = new XMLHttpRequest();

      xhr.open('GET', url, true);
      xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
      xhr.onreadystatechange = function () {
        if (xhr.readyState !== 4) {
          return;
        }
        ModalLoader.resizeModal(width, height);
        modalContent.innerHTML = xhr.responseText;
      };
      xhr.send();
    }
  };

  /**
   * @public
   * initTrigger: add event to those element that need to exceute a callback when modal is open.
   *
   * @param {String} selector to get a group of elements in DOM.
   * @param {Function} callback to execute after modal is loaded.
   */
  ModalLoader.initTrigger = function initTrigger(selector, callback) {
    const elements = document.querySelectorAll(selector);
    if (elements) {
      elements.forEach((element) => {
        element.addEventListener('click', function (event) {
          event.preventDefault();
          event.stopPropagation();
          openUrl.call(this, element, callback);
        });
      });
    }
  };

  /**
   * @public
   *  Create the modal background and modal content only with HTML.
   *
   * @param {String} htmlData to be inner in the HTML.
   * @param {HTMLButtonElement} triggerElement button trigger.
   */
  ModalLoader.createModal = function createModal(htmlData, triggerElement) {
    createBackGroudModal();
    clearModalContent();
    displayBackgroundModal();
    createLoadingAnimation();

    const useA11yDialog = htmlData.querySelector('[data-a11y-dialog]') !== null;
    createModalContent(htmlData, triggerElement, useA11yDialog);
  };

  /**
   * @public
   *  Display the modal background and modal content
   */
  ModalLoader.displayModal = function displayModal() {
    displayModalContainer();
    displayBackgroundModal();
  };

  /**
   * @public
   * hideModal: hide modal in DOM.
   *
   */
  ModalLoader.hideModal = hideModal;

  /**
   * @public
   * showModal: open modal in the DOM.
   *
   */
  ModalLoader.showModal = showModal;

  ModalLoader.resetIsModalLoadedFlag = resetIsModalLoadedFlag;

  document.addEventListener('click', initModal);
  document.addEventListener('click', closeModal);

  global.NewHomeSource.ModalLoader = ModalLoader;

  return ModalLoader;
}(window));

define("windowModal", function(){});

