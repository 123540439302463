(function () {
  function removeFromCookie(items, id) {
    const itemIndex = items.map(function (item) { return JSON.parse(item).id; }).indexOf(id);

    if (itemIndex >= 0) {
      items.splice(itemIndex, 1);
      const cookie = ["pendingSaveItem", '=', JSON.stringify(items), '; domain=.', window.location.host.toString(), '; path=/;'].join('');
      document.cookie = cookie;
    }

    return itemIndex >= 0;
  }

  function saveToFavorites() {
    const pendingItems = document.cookie.match(new RegExp("pendingSaveItem=([^;]+)"));
    const favoriteItems = pendingItems ? JSON.parse(pendingItems[1]) : [];
    const hasSaveToFavoritesScript = document.querySelectorAll(".nhs_favUnsave, .nhs_favSaved").length > 0;

    if (hasSaveToFavoritesScript) {
      return;
    }

    for (let index = favoriteItems.length - 1; index >= 0; index -= 1) {
      const favoriteString = favoriteItems[index];
      const favoriteObject = JSON.parse(favoriteString);
      const xhr = new XMLHttpRequest();
      const data = {
        Type: favoriteObject.type,
        Id: favoriteObject.id,
        FavoriteUrl: favoriteObject.url,
        Title: favoriteObject.name
      }

      xhr.open("POST", "/favorites/save", true);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.send(JSON.stringify(data));
      removeFromCookie(favoriteItems, favoriteObject.id);
    }
  }

  function updateIconCount(favorites) {
    if (favorites) {
      const totalFavoritesSaved = document.querySelector("[data-total-favorites]");

      if (totalFavoritesSaved) {
        totalFavoritesSaved.innerText = favorites.length;
      }
    }
  }

  const pendingItems = document.cookie.match(new RegExp("pendingSaveItem=([^;]+)"));
  const cookieFavorites = pendingItems ? JSON.parse(pendingItems[1]) : [];
  const isLoggedIn = document.querySelector('[data-isloggedin="True"]');
  const isMobile = !!document.querySelector('[data-ismobiledevice="true"]');

  if (cookieFavorites.length === 0) {
    return;
  }

  if (!isMobile) {
    updateIconCount(cookieFavorites);
  }
  isLoggedIn && saveToFavorites(cookieFavorites);

  document.addEventListener("updateSavesWhenLoggedIn", saveToFavorites);
}());
define("favoritesIcon", function(){});

